.custom-dropzone {

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--black-700);
    max-width: 60%;


    ui-icon {
      .icon {
        width: 20px;
        height: 20px;
        background: var(--black-400);
      }
    }
  }

  &__description {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    max-width: 60%;
    text-align: center;
    color: var(--black-600);
    display: flex;
    align-items: center;
    gap: 8px;

    ui-icon {
      .icon {
        width: 16px;
        height: 16px;
        background: var(--success-500);
      }
    }
  }

  &__hint {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--black-400);
    margin: 0;
  }

  .preview-container {
    display: flex;
    align-items: center;
    gap: 17px;
    flex: 1;

    .image-preview {
      width: 80px !important;
      height: max-content !important;
      min-height: max-content !important;
      margin: 0 !important;
    }

    .custom-dropzone {

      &__title {
        max-width: unset;
      }

      &__description {
        max-width: unset;
      }
    }
  }
}
@media screen and (max-width: 456px) {
  .custom-dropzone {
    &__title {
      max-width: 100%;
    }
    &__description{
      max-width: 100%;
    }
  }
}
