.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border: 1px solid var(--black-500, #8F8F8E);
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border: 1px solid var(--makktina-2-500, #FF008A);
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: var(--makktina-2-600, #F00086);
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:focus:enabled:not(:checked) ~ .mdc-radio__background .mdc-radio__outer-circle {
  border: 1px solid var(--makktina-2-500, #FF008A) !important;
}
.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__background .mdc-radio__outer-circle {
  border: 1px solid var(--makktina-2-500, #FF008A) !important;
}
.mat-mdc-radio-button .mdc-form-field .mdc-radio {
  width: 20px;
}
.radio-group .mat-mdc-radio-button .mdc-form-field {
  width: 100%;
}
