.card {
  padding: 24px;
  border-radius: 8px;
  background-color: var(--white-100);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);

  &-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--black-700, #545453);
  }
}

@media screen and (max-width: 540px) {
  .card {
    padding: 16px;
  }
}
