table.custom-table {
  border-spacing: 0;
  width: 100%;

  th, td {
    padding: 14px 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--black-600);

    /*&:last-of-type {
      position: -webkit-sticky;
      position: sticky;
      right: 0;
    }*/
  }

  th {
    position: relative;
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: calc((100% - 24px) / 2);
        width: 1px;
        height: 24px;
        background: var(--black-200);
      }
    }

    & > div {
      //padding-right: 16px;
    }
  }

  td {
    font-weight: 400;
    white-space: nowrap;
  }

  thead {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      border-radius: 6px;
      height: 100%;
      border: 1px solid var(--black-100);
      background-color: var(--black-50);
    }

    tr {
      position: relative;
    }

    th {

      &:not(:last-of-type):after {
        content: '';
        position: absolute;
        top: calc(50% - 12px);
        right: 0;
        height: 24px;
        width: 1px;
        background-color: var(--black-200);
      }
    }
  }

  tbody {
    &::before {
      content: "@";
      display: block;
      line-height: 4px;
      text-indent: -99999px;
    }

    tr {
      border-top: 1px solid var(--black-200);

      &.selected,
      &.selected td {
        background-color: var(--makktina-2-50);
      }

      td {
        position: relative;
      }
    }
  }
}
